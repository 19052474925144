import { Route } from "react-router-dom";
import React, { Suspense, lazy, Fragment, Component } from "react";
import Loader from "react-loaders";
import Global from "../../common/Global";
import { retrieveUserInfoInLocalStorage } from "../../helpers/auth";

const Analytics = lazy(() => import("../../Pages/Analytics"));
const ListOrders = lazy(() => import("../../Pages/Orders/List"));
const OrderDetails = lazy(() => import("../../Pages/Orders/Details"));
const Products = lazy(() => import("../../Pages/Products"));
const AllSellers = lazy(() => import("../../Pages/Sellers/AllSellers"));
const AddNewSeller = lazy(() => import("../../Pages/Sellers/AddNewSeller"));
const PendingApplications = lazy(() =>
  import("../../Pages/Sellers/PendingApplications")
);
const Dashboard = lazy(() => import("../../Pages/Dashboard"));
const Login = lazy(() => import("../../Pages/UserPages/Login"));
const Forgot = lazy(() => import("../../Pages/UserPages/Forgot"));
const Reset = lazy(() => import("../../Pages/UserPages/Reset"));
const RequestForQuotations = lazy(() => import("../../Pages/RFQs"));
const Customers = lazy(() => import("../../Pages/Customers"));
const Users = lazy(() => import("../../Pages/Users"));
const MarketPrices = lazy(() => import("../../Pages/MarketPrices"));
const Reports = lazy(() => import("../../Pages/Reports"));
const Contacts = lazy(() => import("../../Pages/Contacts"));
const QuotationsList = lazy(()=> import ('../../Pages/Sellers/ProductsQuotes/List'))
const QuotationsDetails = lazy(()=> import ('../../Pages/Sellers/ProductsQuotes/Details'))
class AppMain extends Component {
  constructor(props) {
    super();
    this.state = {
      path: "",
      activeUserRole: "",
    };
  }

  componentDidMount() {
    this.getloggedInUser();
  }

  getloggedInUser = async () => {
    const authUser = await retrieveUserInfoInLocalStorage();
    if (authUser) {
      const role = authUser.role_type;

      if (role === "admin") {
        this.setState({
          path: "/analytics",
          activeUserRole: role,
        });
      } else if (role === "seller") {
        this.setState({
          path: `/analytics?seller=${authUser.id}`,
          activeUserRole: role,
        });
      }
    }
  };

  render() {
    return (
      <Fragment>
        {/* Login */}

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="line-scale-party" />
                </div>
              </div>
            </div>
          }
        >
          <Route exact path="/" component={Login} />
        </Suspense>

        {/* Forgot */}

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="line-scale-party" />
                </div>
              </div>
            </div>
          }
        >
          <Route exact path="/forgot" component={Forgot} />
        </Suspense>

        {/* Reset */}

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="line-scale-party" />
                </div>
              </div>
            </div>
          }
        >
          <Route exact path="/reset" component={Reset} />
        </Suspense>

        {/* Analytics */}

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse" />
                </div>
              </div>
            </div>
          }
        >
          <Route path="/analytics" component={Analytics} />
        </Suspense>

        {/* orders */}

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse" />
                </div>
              </div>
            </div>
          }
        >
          <Route path="/orders" component={ListOrders} />
        </Suspense>

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse" />
                </div>
              </div>
            </div>
          }
        >
          <Route path="/orders-details/:product_id" component={OrderDetails} />
        </Suspense>

        {/* products */}

        <Suspense
          fallback={
            <Global>
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-pulse" />
                  </div>
                </div>
              </div>
            </Global>
          }
        >
          <Route path="/products" component={Products} />
        </Suspense>

        {/* products Quotations */}

        <Suspense
          fallback={
            <Global>
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-pulse" />
                  </div>
                </div>
              </div>
            </Global>
          }
        >
          <Route path="/quotes" component={QuotationsList} />
        </Suspense>
        <Suspense
          fallback={
            <Global>
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-pulse" />
                  </div>
                </div>
              </div>
            </Global>
          }
        >
          <Route path="/quote-detail/:quote_id" component={QuotationsDetails} />
        </Suspense>

        {/* Customers */}

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse" />
                </div>
              </div>
            </div>
          }
        >
          <Route path="/customers" component={Customers} />
        </Suspense>

        {/* Sellers */}

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse" />
                </div>
              </div>
            </div>
          }
        >
          <Route exact path="/sellers" component={AllSellers} />
        </Suspense>
        <Suspense
          fallback={
            <Global>
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-pulse" />
                  </div>
                </div>
              </div>
            </Global>
          }
        >
          <Route exact path="/sellers/:seller_id" component={AddNewSeller} />
        </Suspense>
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse" />
                </div>
              </div>
            </div>
          }
        >
          <Route
            exact
            path="/sellers-pending-applications"
            component={PendingApplications}
          />
        </Suspense>

        {/* Request for quotations */}

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse" />
                </div>
              </div>
            </div>
          }
        >
          <Route path="/rfqs" component={RequestForQuotations} />
        </Suspense>

        {/* Settings */}

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-grid-cy" />
                </div>
              </div>
            </div>
          }
        >
          <Route path="/users" component={Users} />
        </Suspense>

        {/* Market prices */}

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse" />
                </div>
              </div>
            </div>
          }
        >
          <Route path="/market-prices" component={MarketPrices} />
        </Suspense>

        {/* Report analytics */}

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse" />
                </div>
              </div>
            </div>
          }
        >
          <Route path="/reports" component={Reports} />
        </Suspense>

        {/* Contacts */}

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse" />
                </div>
              </div>
            </div>
          }
        >
          <Route path="/contacts" component={Contacts} />
        </Suspense>

        {/* Dashboards */}

        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-grid-cy" />
                </div>
              </div>
            </div>
          }
        >
          <Route path="/dashboard" component={Analytics} />
        </Suspense>

        {/* <Route exact path="/" render={() => (
                <Redirect to="/dashboard"/>
            )}/> */}
      </Fragment>
    );
  }
}

export default AppMain;
