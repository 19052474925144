/**
 * APP CONSTANTS
 */
// export const API_URL = "http://localhost:1338";
export const API_URL = "https://api.esoko.rw";
export const SARURA_API_URL = "https://api.sarura.rw/api/v1";
export const ADMIN_AGRO_AUTH_TOKEN = "ADMIN_AGRO_AUTH_TOKEN";
export const ADMIN_AGRO_AUTH_USER = "ADMIN_AGRO_AUTH_USER";
export const ADMIN_AGRO_SELLER_FIELDS = "ADMIN_AGRO_SELLER_FIELDS";
export const WARNING_MESSAGE_SESSION = "WARNING_MESSAGE_SESSION";

/**
 * THIRD PARTY CONSTANTS
 */
// export const CLOUDINARY_API_URL = "https://api.cloudinary.com/v1_1/hehe/upload";
export const CLOUDINARY_API_URL = "https://api.cloudinary.com/v1_1/dqlwzjuwr/upload";
// export const CLOUDINARY_PRESET = "rkfkbm9z";
export const CLOUDINARY_PRESET = "pcnz82qa";
export const APP_KEY = "0a0e4ea1099c464e467a";
export const APP_CLUSTER = "ap2";

/**
 * CACHE TTL CONSTANTS
 */
export const AUTH_TOKEN_CACHE_TTL = 172800000; // 2 days
export const AUTH_USER_CACHE_TTL = 172800000;
export const SELLER_TABLE_FIELDS_CACHE_TTL = 86400000; // 1 day

/**
 * USER MESSAGE CONSTANTS
 */
export const API_ERROR_500_MESSAGE =
  "OOPS! Looks like our service is down. Please try again!";
export const API_ERROR_403_MESSAGE =
  "OOPS! Looks like you don't have access to view this resource. Please try again or contact the support team to grant you access";
export const API_ERROR_UNKNOWN_MESSAGE =
  "OOPS! Looks like our service is down. Please try again!";
export const APP_ERROR_MESSAGE =
  "OOPS! Something went wrong. Please check if you have an active internet connection and try again!";

export const COUNTRIES_API_URL =
  "https://iposita.fleet.rw/assets/json/countries.d/iso_countries.json";
export const REGIONS_API_URL =
  "https://iposita.fleet.rw/v1/api/place/select/region?country_iso=rw&isajax=true";
export const NGHB_STATE_HEADER = 1;
export const NGHB_COMPANY_HEADER = 3;
export const NGHB_APPNAME_HEADER = "web.ems.iposita.rw";
export const NGHB_APPKEY_HEADER =
  "9hCdjkk5HsMXREgLLcUnq96HjLYpYvgmt57fhRZ4WP26q5SQBpyADzmxa2VXGunr";
export const NGHB_APIKEY_HEADER =
  "JsXK37bzmWPVFF5PLKDnTSTrS5WDxvgfXMrTw2zHbXwHjKVznunjuf97dvYeEbpt";

export const FARMER_API_KEY =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiMDg0MWMzM2FlYWZiNTdmMzZjNzVjNjA3NDFlZmVmNDYwNTFmZDA1NmFhODViYmVjMDI2NjEwNTcwYzNlMzVkMmUzYzkxNGFmZWNkMDAxOWIiLCJpYXQiOjE1OTcyNDUxNjgsIm5iZiI6MTU5NzI0NTE2OCwiZXhwIjoxNjI4NzgxMTY4LCJzdWIiOiI5Iiwic2NvcGVzIjpbIioiXX0.CGZL0hWExlTJZOnbUWCvLYWrfNpxGE49L4zTe9h9EjINVwZ4XRFkn58XnU4MPJV3r2NQpCNMOwjz4s_uNnI4h5fBUnWQXTEz14hrYA9jLzx0vrGpVSXQaahVLJUEJPB4jB7sLIpCP57WFhKfpl-Ut0Xh6DWyT87BFJL4tof5KuJAaxqeqTuCVrDFgVntF_DbQoLJY--w1D6sl_DBD7DhFv8KwQ4PMkyhp4Kdqma_roilDLmLVDRQpZxrSFMmVOGO-p7xRJVzgvbe8hlB919Fvmc99vlvwG245ne8PtzXtkMTEkt5RUmhDXDKPx8bWYctkueQy3oBKzj4D7ENv6H_vwxBlVcAQTtcfEhRmZfkReC9EYNWADcYHC1qPIlWUVG8ITkOs4b33-I3yeMpQySk87kPJq-ZtaHSJ3RMM6Ds9CfkcBVAKayaGJnalDwguMCjMlsQA3rdSK0WTxU94gd1R7z_mGsF3C3oGCv1BpSSbC8E9hNrRA_LbaOzJL9RDzBsS7NXj6uLz1sne-6vajGSUo50K6avpASs4PhlS-_1_z0O9gjNdgHrBI9kJt4yD6_zYa8OWMZCnk-AZbYg2P9ZVIPDLjQZAtRYlcskzg5QCSmWwTS5_o1LcBtn25Vy3TZgPVaxIzy2BPQKzTfEJOt3guIoD-tdFxsrZlrSipkyfTw";
