export const MainNav = [
  {
    icon: "pe-7s-graph3",
    label: "Analytics",
    to: "#/analytics",
  },
  {
    icon: "pe-7s-mail",
    label: "Orders",
    to: "#/orders",
  },
  {
    icon: "pe-7s-ribbon",
    label: "Quotes",
    to: "#/quotes",
  },
  {
    icon: "pe-7s-rocket",
    label: "Products",
    content: [
      {
        label: "All Products",
        to: "#/products/all-products",
      },
      {
        label: "Add New Product",
        to: "#/products/add-new-product",
      },
      {
        label: "Categories",
        to: "#/products/categories",
      },
    ],
  },
  {
    icon: "pe-7s-user",
    label: "Customers",
    to: "#/customers",
  },
  {
    icon: "pe-7s-browser",
    label: "Sellers",
    content: [
      {
        label: "All Sellers",
        to: "#/sellers",
      },
      {
        label: "Pending Applications",
        to: "#/sellers-pending-applications",
      },
    ],
  },
  {
    icon: "pe-7s-id",
    label: "RFQs",
    to: "#/rfqs/list",
  },
  {
    icon: "pe-7s-config",
    label: "Settings",
    content: [
      {
        label: "All Users",
        to: "#/users/all-users",
      },
      {
        label: "Add New User",
        to: "#/users/add-new-user",
      },
    ],
  },
  {
    icon: "pe-7s-id",
    label: "Market Prices",
    content: [
      {
        label: "Local Markets",
        content: [
          {
            label: "Markets",
            to: "#/market-prices/local-markets/markets",
          },
          {
            label: "Commodities",
            to: "#/market-prices/local-markets/commodities",
          },
        ],
      },
      {
        label: "Exports",
        content: [
          {
            label: "Markets",
            to: "#/market-prices/exports/markets",
          },
          {
            label: "Commodities",
            to: "#/market-prices/exports/commodities",
          },
        ],
      },
      {
        label: "eSOKO Analytics",
        to: "#/market-prices/esoko-analytics",
      },
    ],
  },
  {
    icon: "pe-7s-id",
    label: "Reporting",
    to: "#/market/marketPrices",
  },
];
