import React, { Component, Fragment } from "react";
import Select from "react-select";
import { withRouter } from "react-router-dom";

import { FormGroup } from "reactstrap";
import { getSellers } from "../../../helpers/product";
import { retrieveUserInfoInLocalStorage } from "../../../helpers/auth";

class SellersDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSeller: null,
      sellers: [],
      defaultV: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    try {
      this.loadSellers();
    } catch (error) {
      console.log(`Error occurred at componedDidMount() \nERROR:${error}`);
    }
  }

  loadSellers = async () => {
    try {
      var user = retrieveUserInfoInLocalStorage();
      var data = getSellers();
      data = await data;
      user = await user;
      console.log('====sellers droppdown data ===', data)
      /**
       * Return error messages
       * */
      if (data.has_error) return;

      /**
       * Set Values in Select Field
       * */
      let sellers = data.data.map(function(elm) {
        return { label: elm.username, value: elm.id };
      });

      this.setState({ sellers });

      if (user.role_type === "seller") {
        this.setState({
          selectedSeller: sellers.find((seller) => seller.value === user.id),
        });
        return;
      }

      if (this.props.history.location.search !== "") {
        const query = this.props.history.location.search.split("=");
        const id = query[1];
        this.setState({
          selectedSeller: sellers.find((seller) => seller.value === id),
        });
      }
    } catch (error) {
      console.log(`Error occurred at loadSellers() \nERROR: ${error}`);
    }
  };

  handleChange = (selectedSeller) => {
    this.setState({ selectedSeller });
    this.props.history.push(`/analytics?seller=${selectedSeller.value}`);
    window.location.reload();
  };

  render() {
    const { selectedSeller, sellers } = this.state;
    // const query = this.props.history.location.search.split('=');
    // const id = query[1];
    // const defaultV = sellers.find(seller => seller.value);
    return (
      <div className="sellers-dropdown">
        {/* <FormGroup>
          <Select
            //defaultValue={sellers[0]}
            value={selectedSeller}
            onChange={this.handleChange}
            options={sellers}
          />
        </FormGroup> */}
      </div>
    );
  }
}

export default withRouter(SellersDropdown);
