import axios from 'axios';
import { CLOUDINARY_API_URL, API_ERROR_UNKNOWN_MESSAGE, APP_ERROR_MESSAGE } from '../config/app';

const uploadImageOnCloudinary = async images => {
    const response = {
        has_error: false,
        data: null,
        errors: []
    };
    try {
        const serverResponse = await axios.request({
            url: CLOUDINARY_API_URL,
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': false
            },
            data: images,
            validateStatus: status => {
                if (status >= 200 && status < 500) {
                    return true;
                }
                return false;
            }
        });
        switch (serverResponse.status) {
            case 200:
                response.data = serverResponse.data;
                break;
            default:
                response.has_error = true;
                if (response.data.error.message) {
                    response.errors.push(response.data.error.message);
                } else {
                    response.errors.push(API_ERROR_UNKNOWN_MESSAGE);
                }

                console.log(`Unknown api server response status uploadImageOnCloudinary()`, serverResponse.data);
                break;
        }
    } catch (err) {
        console.log(`an error happened when uploadImageOnCloudinary()`, err);
        response.has_error = true;
        response.errors.push(APP_ERROR_MESSAGE);
    }

    return response;
};
const uploadFilesOnCloudinary = async files => {
    const response = {
        has_error: false,
        data: null,
        errors: []
    };
    try {
        const serverResponse = await axios.request({
            url: CLOUDINARY_API_URL,
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': false
            },
            data: files,
            validateStatus: status => {
                if (status >= 200 && status < 500) {
                    return true;
                }
                return false;
            }
        });
        switch (serverResponse.status) {
            case 200:
                response.data = serverResponse.data;
                break;
            default:
                response.has_error = true;
                if (response.data.error.message) {
                    response.errors.push(response.data.error.message);
                } else {
                    response.errors.push(API_ERROR_UNKNOWN_MESSAGE);
                }

                console.log(`Unknown api server response status uploadFilesOnCloudinary()`, serverResponse.data);
                break;
        }
    } catch (err) {
        console.log(`an error happened when uploadFilesOnCloudinary()`, err);
        response.has_error = true;
        response.errors.push(APP_ERROR_MESSAGE);
    }

    return response;
};

export { uploadImageOnCloudinary, uploadFilesOnCloudinary };
