import React, { Fragment } from 'react';

import {
    DropdownToggle, 
    DropdownMenu,
    Button,
    UncontrolledButtonDropdown
} from 'reactstrap';

import {
    toast,
    Bounce
} from 'react-toastify';


import {
    faAngleDown,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { retrieveUserInfoInLocalStorage, logoutUser } from '../../../helpers/auth';
import { withRouter } from 'react-router-dom';

class UserBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            user: null
        };
        this.initializeComponent = this.initializeComponent.bind(this);
    }

    componentDidMount() {
        this.initializeComponent();
    }

    initializeComponent = async () => {
        try {
            const user = await retrieveUserInfoInLocalStorage();
            if (user) {
                this.setState({
                    user
                });
            }
        } catch (err) {
            console.log('an error happened in initializeComponent() under /Layout/AppHeader/Components/UserBox', err);
        }
    }

    handleLogout = async () => {
        await logoutUser();
        this.props.history.push('/');
    } 

    notify2 = () => this.toastId = toast("You don't have any new items in your calendar for today! Go out and play!", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-center',
        type: 'success'
    });


    render() {
        const { user } = this.state;
        return (
            <Fragment>
                <div className="header-btn-lg pr-0">
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color="link" 
                                    className="p-0"
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                    >
                                        <span width={42}
                                            className="rounded-circle"
                                            style={{
                                                fontSize: '1.2rem',
                                                fontWeight: 700,
                                                textTransform: 'capitalize'
                                            }}
                                        >{user ? user.username[0] : 'N'}</span>
                                        <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown} />
                                    </DropdownToggle>
                                    <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                                        <div className="dropdown-menu-header">
                                            <div className="dropdown-menu-header-inner bg-info">
                                                <div className="menu-header-image opacity-2"

                                                />
                                                <div className="menu-header-content text-left">
                                                    <div className="widget-content p-0">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left mr-3">
                                                                <span width={42} className="rounded-circle" />
                                                            </div>
                                                            <div className="widget-content-left">
                                                                <div className="widget-heading">
                                                                    {user ? user.username : '...'}
                                                                </div>
                                                                <div className="widget-subheading opacity-8">
                                                                    {user ? user.role_type : '...'}
                                                                </div>
                                                            </div>
                                                            <div className="widget-content-right mr-2">
                                                                <Button 
                                                                onClick={this.handleLogout}
                                                                className="btn-pill btn-shadow btn-shine"
                                                                    color="focus">
                                                                    Logout
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className="widget-content-left  ml-3 header-user-info">
                                <div className="widget-heading">
                                    {user ? user.username : '...'}
                                </div>
                                <div className="widget-subheading">
                                    {user ? user.role_type : '...'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(UserBox);