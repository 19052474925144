import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import { MainNav } from "./NavItems";
import { logoutUser, retrieveUserInfoInLocalStorage } from "../../helpers/auth";
import { getRemoteData } from "../../helpers/remote";
import { API_URL } from "../../config/app";

const sellerNav = [
  {
    icon: "pe-7s-graph3",
    label: "Analytics",
    to: "#/analytics",
  },
  {
    icon: "pe-7s-mail",
    label: "Orders",
    to: "#/orders",
  },
  {
    icon: "pe-7s-ribbon",
    label: "Quotes",
    to: "#/quotes",
  },
  {
    icon: "pe-7s-rocket",
    label: "Products",
    content: [
      {
        label: "All Products",
        to: "#/products/all-products",
      },
      {
        label: "Add New Product",
        to: "#/products/add-new-product",
      },
    ],
  },
  {
    icon: "pe-7s-browser",
    label: "Profile",
    content: [
      {
        label: "Account",
        to: "#/sellers",
      },
    ],
  },
//   {
//     icon: "pe-7s-config",
//     label: "Help Desk",
//     content: [
//       {
//         label: "Chat",
//         to: "#/users/chat",
//       },
//     ],
//   },
  // {
  //   icon: "pe-7s-id",
  //   label: "RFQs",
  //   to: "#/rfqs/list",
  // },
];

const adminNav = [
  {
    icon: "pe-7s-graph3",
    label: "Analytics",
    to: "#/analytics",
  },
  {
    icon: "pe-7s-mail",
    label: "Orders",
    to: "#/orders",
  },
  {
    icon: "pe-7s-rocket",
    label: "Products",
    content: [
      {
        label: "All Products",
        to: "#/products/all-products",
      },
      {
        label: "Add New Product",
        to: "#/products/add-new-product",
      },
      {
        label: "Categories",
        to: "#/products/categories",
      },
    ],
  },
  // {
  //   icon: "pe-7s-user",
  //   label: "Customers",
  //   to: "#/customers",
  // },
  {
    icon: "pe-7s-browser",
    label: "Sellers",
    content: [
      {
        label: "All Sellers",
        to: "#/sellers",
      },
      {
        label: "Pending Applications",
        to: "#/sellers-pending-applications",
      },
    ],
  },
  // {
  //   icon: "pe-7s-id",
  //   label: "RFQs",
  //   to: "#/rfqs/list",
  // },
  {
    icon: "pe-7s-config",
    label: "Settings",
    content: [
      {
        label: "All Users",
        to: "#/users/all-users",
      },
      {
        label: "Add New User",
        to: "#/users/add-new-user",
      },
      {
        label: "Payment and shipping",
        to: "#/users/payment&shipping",
      },
    ],
  },
//   {
//     icon: "pe-7s-id",
//     label: "Market Prices",
//     content: [
//       {
//         label: "Local Markets",
//         content: [
//           {
//             label: "Markets",
//             to: "#/market-prices/local-markets/markets",
//           },
//           {
//             label: "Commodities",
//             to: "#/market-prices/local-markets/commodities",
//           },
//         ],
//       },
//       // {
//       //   label: "Exports",
//       //   content: [
//       //     {
//       //       label: "Markets",
//       //       to: "#/market-prices/exports/markets",
//       //     },
//       //     {
//       //       label: "Commodities",
//       //       to: "#/market-prices/exports/commodities",
//       //     },
//       //   ],
//       // },
//       {
//         label: "eSOKO Analytics",
//         to: "#/market-prices/esoko-analytics",
//       },
//     ],
//   },
//   {
//     icon: "pe-7s-note2",
//     label: "Reporting",
//     content: [
//       // {
//       //   label: 'Orders',
//       //   to: '#/reports/orders'
//       // },
//       {
//         label: "Market Prices",
//         to: "#/Products/market/marketPrices",
//         label: "esoko",
//         to: "#/reports/esoko",
//       },
//     ],
//   },
//   {
//     icon: "pe-7s-note",
//     label: "Subscribers",
//     to: "#/Products/subscribers/subscriberLists",
//   },
];

const privilegedAdminNav = adminNav;
// privilegedAdminNav.push({
//   icon: "pe-7s-speaker",
//   label: "Contacts",
//   to: "#/contacts/all-contacts",
// });

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appNavigator: [],
      navItemSet: false,
    };
    this.getLoggedInUser = this.getLoggedInUser.bind(this);
  }

  componentDidMount() {
    this.getLoggedInUser();
  }

  getLoggedInUser = async () => {
    try {
      const user = await retrieveUserInfoInLocalStorage();
      if (user.role_type === "seller" || user.role_type === "Seller") {
        this.setState({
          appNavigator: sellerNav,
        });
      }
      else if(user.role_type === 'Markeplace Admin' || user.role_type === 'markeplace admin' ){
         this.setState({
            appNavigator: adminNav,
          });
      }
      else if(user.role_type === 'Admin' || user.role_type === 'admin' ){
         this.setState({
            appNavigator: adminNav,
          });
      } 
      else {
         await logoutUser()
         window.location.replace('https://esoko.rw')
         return
        const url = `${API_URL}/access-privileges?identifier=receiving-client-info&users=${user.id}?`;
        const data = await getRemoteData(url, "GET", "getLoggedInUser");
        if (data.data.length > 0) {
          this.setState({
            appNavigator: privilegedAdminNav,
          });
        } else {
          this.setState({
            appNavigator: adminNav,
          });
        }
      }
    } catch (error) {
      console.log(
        "an error happened in Layout/AppNav/VerticalNavWrapper",
        error
      );
    }
  };

  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  render() {
    const { appNavigator } = this.state;
    return (
      <Fragment>
        {/* <h5 className="app-sidebar__heading">Menu</h5> */}
        <MetisMenu
          content={appNavigator}
          onSelected={this.toggleMobileSidebar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
