import axios from "axios";
import localforage from "localforage";
import {
  API_URL,
  API_ERROR_500_MESSAGE,
  APP_ERROR_MESSAGE,
  API_ERROR_UNKNOWN_MESSAGE,
  ADMIN_AGRO_AUTH_TOKEN,
  ADMIN_AGRO_AUTH_USER,
  AUTH_TOKEN_CACHE_TTL,
  AUTH_USER_CACHE_TTL,
} from "../config/app";

/**
 * Logs in the user
 *
 * @param {string} email
 * @param {string} password
 */
export const logInUser = async (email, password) => {
  const response = {
    has_error: false,
    data: {},
    errors: [],
  };
  try {
    const url = `${API_URL}/auth/local`;
    const serverResponse = await axios.request({
      url,
      method: "POST",
      data: {
        identifier: email,
        password,
      },
      validateStatus: (status) => {
        if (status >= 200 && status < 500) {
          return true;
        }
        return false;
      },
    });

    switch (serverResponse.status) {
      case 200:
        const token = serverResponse.data.jwt;
        const { id, username, email, role } = serverResponse.data.user;
        const user = {
          id,
          username,
          email,
          role_type: role.type,
          role_id: role.id,
        };

        if (token) {
          await storeAuthUserTokenInLocalStorage(token);
        }

        if (user) {
          await storeAuthUserInfoInLocalStorage(user);
        }

        response.data.url = "/analytics";

        break;
      case 400:
        const errors = serverResponse.data.data[0].messages;
        console.log("Authentication error logInUser()", errors);
        response.has_error = true;
        response.errors.push(errors[0].message);
        break;
      case 500:
        console.log("Api server error logInUser()", serverResponse.data.data);
        response.has_error = true;
        response.errors.push(API_ERROR_500_MESSAGE);
        break;
      default:
        response.has_error = true;
        response.errors.push(API_ERROR_UNKNOWN_MESSAGE);
        console.log(
          "Unknown api server response statuslogInUser()",
          serverResponse.data
        );
        break;
    }
  } catch (err) {
    console.log(
      "an error happened when logging in the user in logInUser()",
      err
    );
    response.has_error = true;
    response.errors.push(APP_ERROR_MESSAGE);
  }

  return response;
};

/**
 * Stores user auth token in the localstorage
 *
 * @param {string} token
 */
export const storeAuthUserTokenInLocalStorage = async (token) => {
  try {
    var now = new Date();
    const item = {
      value: token,
      expiry: now.getTime() + AUTH_TOKEN_CACHE_TTL,
    };
    await localforage.setItem(ADMIN_AGRO_AUTH_TOKEN, item);
  } catch (e) {
    console.log(
      "an error happened when storing user in localstorage storeUserAuth()",
      e
    );
  }
};

/**
 * Stores the user info in the localstorage
 *
 * @param {object} userInfo
 */
export const storeAuthUserInfoInLocalStorage = async (userInfo) => {
  try {
    var now = new Date();
    const item = {
      value: userInfo,
      expiry: now.getTime() + AUTH_USER_CACHE_TTL,
    };
    await localforage.setItem(ADMIN_AGRO_AUTH_USER, item);
  } catch (err) {
    console.log(
      "an error happened when trying to store auth user info in localstorage storeAuthUserInfoInLocalStorage()",
      err
    );
  }
};

/**
 * Retrieves the user auth token from the local storage
 *
 */
export const retriveAuthTokenFromLocalStorage = async () => {
  var authToken = null;
  try {
    var tokenData = await localforage.getItem(ADMIN_AGRO_AUTH_TOKEN);
    if (tokenData) {
      var now = new Date();
      if (now.getTime() < tokenData.expiry) {
        authToken = tokenData.value;
      } else {
        /**
         * Delete expired token
         */
        await localforage.removeItem(ADMIN_AGRO_AUTH_TOKEN);
      }
    }
  } catch (e) {
    console.log(
      "an error happened when trying to retriveAuthTokenFromLocalStorage()",
      e
    );
  }
  return authToken;
};

/**
 * Retrieves the user info in the local storage
 */
export const retrieveUserInfoInLocalStorage = async () => {
  var user = null;
  try {
    var userData = await localforage.getItem(ADMIN_AGRO_AUTH_USER);
    if (userData) {
      var now = new Date();
      if (now.getTime() < userData.expiry) {
        user = userData.value;
      } else {
        /**
         * TO DO
         * Retrieve user info from the api if user is logged in
         */
        await localforage.removeItem(ADMIN_AGRO_AUTH_USER);
      }
    }
  } catch (e) {
    console.log(
      "an error happened when trying to retrieveUserInfoInLocalStorage()",
      e
    );
  }
  return user;
};

/**
 * Checks if user is logged in
 */
export const isUserLoggedIn = async () => {
  let userLoggedInValidity = false;
  try {
    var token = await retriveAuthTokenFromLocalStorage();
    if (token) {
      userLoggedInValidity = true;
    }
  } catch (e) {
    console.log(
      "an error happened when checking if user is logged in isUserLoggedIn()",
      e
    );
  }
  return userLoggedInValidity;
};

export const logoutUser = async () => {
  try {
    await localforage.removeItem(ADMIN_AGRO_AUTH_TOKEN);
    await localforage.removeItem(ADMIN_AGRO_AUTH_USER);
  } catch (e) {
    console.log(
      "an error happened when trying to log out user in auth/logoutUser()",
      e
    );
  }
};
